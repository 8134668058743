<template>
  <div id="category-wrapper" class="position-relative legacy-inputs" :data-elbcontext="elbContext">
    <SlideDown>
      <CategorySmartBlock v-if="category.code" :block="blocks.before">
        <TheSearchHeader v-if="isSearch"/>
      </CategorySmartBlock>
    </SlideDown>
    <TheFilter v-if="category.code"/>
    <LazyComponent v-if="customerSoftLoggedIn" id="after-html" :delay="1500">
      <CategorySmartBlock v-if="category.code" :block="blocks.after"/>
    </LazyComponent>
    <LazyComponent v-if="customerSoftLoggedIn" id="recent-items" :delay="1500">
      <TheRecentlyViewedItems  class="border-winered pt-5"/>
    </LazyComponent>
    <MultiTools id="fixed-tools" v-if="customerIsAdmin && adminTool">
      <Tool :type="adminTool.EDIT_CATEGORY" :id="category.code"/>
      <Tool v-show="isSearch" :type="adminTool.TOGGLE_SOLR_DATA"/>
    </MultiTools>
  </div>
</template>

<script>
import TheFilter from '@/components/category/organisms/TheFilter'
import { mapState } from 'pinia'
import { useCustomerStore } from '@/stores/customer'
import { useSearchConfig } from '@/stores/searchConfig'

const LazyComponent  = () => import('@/components/basic/atoms/LazyComponent.vue')
const SlideDown = () => import('@/components/transitions/SlideDown.vue')
const MultiTools = () => import('@/components/basic/molecules/MultiTools')
const Tool = () => import('@/components/basic/atoms/Tool')
const TheRecentlyViewedItems = () => import('@/components/basic/molecules/TheRecentlyViewedItems')
const CategorySmartBlock = () => import('@/components/category/molecules/CategorySmartBlock')
const TheSearchHeader = () => import('@/components/category/atoms/TheSearchHeader')

export default {
  name: 'TheCategoryTemplate',
  components: {
    LazyComponent,
    SlideDown,
    CategorySmartBlock,
    TheSearchHeader,
    TheFilter,
    Tool,
    MultiTools,
    TheRecentlyViewedItems
  },
  data() {
    return {
      contentReady: false,
      adminTool: null
    }
  },
  computed: {
    ...mapState(useSearchConfig, ['category', 'blocks', 'getConfig', 'elbwalkerContext']),
    ...mapState(useCustomerStore, ['customerIsAdmin', 'customerSoftLoggedIn']),
    isSearch() {
      return !!this.getConfig.query?.original
    },
    elbContext() {
      return this.elbwalkerContext.join(';')
    }
  },
  mounted () {
     this.$nextTick(() => {
      this.contentReady = true
    })
  },
  async created() {
    const { TYPES } = await import('@/constants/AdminTool')
    this.adminTool = TYPES

    if (this.getConfig && this.blocks?.length) {
      this.contentReady = true
    }
  }
}
</script>
<style scoped lang="scss">
#category-wrapper {
  min-height: 100vh;
}

.fade-enter-active {
  transition: all $transition-duration-xxxxl $transition-timing-cb-vicampo;
  opacity: 1;
}

.fade-enter {
  opacity: 0;
}
</style>
